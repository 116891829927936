// Our main CSS
import '../css/app.css'
import lazySizes from 'lazysizes'



document.addEventListener('DOMContentLoaded', function() {
  // Select the mobile menu toggle button and the mobile menu
  var menuToggle = document.getElementById('mobile-menu-toggle');
  var mobileMenu = document.getElementById('mobile-menu');

  // Event listener for the menu toggle button
  menuToggle.addEventListener('click', function() {
      // Check if the mobile menu is visible
      if (mobileMenu.style.display === 'none' || mobileMenu.style.display === '') {
          // Show the mobile menu and change button text to 'Close'
          mobileMenu.style.display = 'block';
          menuToggle.textContent = 'Stäng';
      } else {
          // Hide the mobile menu and change button text to 'Menu'
          mobileMenu.style.display = 'none';
          menuToggle.textContent = 'Meny';
      }
  });

  const items = document.querySelectorAll(".color-change-items");

  function colorChange() {
    for (var i = 0; i < items.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = items[i].getBoundingClientRect().top;
      var elementVisible = 300;

      if (elementTop < windowHeight - elementVisible) {
        if (!items[i].classList.contains('color-changed')) {
          if (items[i].classList.contains('is-white')) {
            items[i].classList.remove('is-white');
          }
          else {
            items[i].classList.add('is-white');
          }
          items[i].classList.add('color-changed');
        }
      }
    }
  }

  if (items) {
    window.addEventListener("scroll", colorChange);
  }

});
